/* eslint-disable import/prefer-default-export */
/* eslint-disable no-console */
import { isMobile } from 'react-device-detect';
import GamesApi from './GamesApi';

class Games {
  getCategories = async () => {
    try {
      const categories = await GamesApi.client.get('games/categories/');
      return categories;
    } catch (error) {
      console.error(error);
      return { error: error.message, status: error.request.status };
    }
  };

  getProviders = async () => {
    try {
      const providers = await GamesApi.client.get('games/providers/');
      return providers;
    } catch (error) {
      console.error(error);
      return { error: error.message, status: error.request.status };
    }
  };

  getAllGames = async () => {
    try {
      const games = await GamesApi.client.get(
        `games-stream?status__in=enabled,maintenance&supports_${
          isMobile ? 'mobile' : 'desktop'
        }=true`
      );
      return games;
    } catch (error) {
      return { error: error.message, status: error.request.status };
    }
  };

  getGameBySlug = async (gameSlug) => {
    try {
      const gameResponse = await GamesApi.client.get(
        `game/?slug=${gameSlug}&skip_disabled_providers=false`
      );
      return gameResponse;
    } catch (error) {
      return { error: error.message, status: error.request?.status };
    }
  };

  getGamesByStudioSlug = async (
    studioId,
    countryPreview,
    offSet,
    more,
    isWhiteListed
  ) => {
    const initialUrl = `games/?studio=${studioId}${
      isWhiteListed ? '' : '&status__in=enabled,maintenance'
    }&limit=24&supports_${isMobile ? 'mobile' : 'desktop'}=true${
      countryPreview ? `&country_preview=${countryPreview}` : ''
    }${isWhiteListed ? '&skip_disabled_providers=false' : ''}`;

    const nextUrl = `games/?studio=${studioId}&limit=24&offset=${offSet}${
      isWhiteListed ? '' : '&status__in=enabled,maintenance'
    }&supports_${isMobile ? 'mobile' : 'desktop'}=true${
      countryPreview ? `country_preview=${countryPreview}` : ''
    }${isWhiteListed ? '&skip_disabled_providers=false' : ''}`;

    const gameUrl = more ? nextUrl : initialUrl;

    try {
      const gamesByStudio = await GamesApi.client.get(gameUrl);
      return gamesByStudio;
    } catch (error) {
      return { error: error.message, status: error.request.status };
    }
  };

  getGamesByCategories = async (category) => {
    try {
      const response = await GamesApi.client.get(
        `games-stream/${category}/?&status__in=enabled,maintenance&supports_${
          isMobile ? 'mobile' : 'desktop'
        }=true`,
        {
          transformResponse: (data) => {
            if (typeof data === 'string') {
              const json = JSON.stringify(data).replace(/\]\[/g, ',');
              const stringA = JSON.parse(JSON.parse(json));
              return stringA;
            }
            return data;
          },
        }
      );

      return response;
    } catch (error) {
      console.log(error);
      return { error: error.message, status: error.request?.status };
    }
  };

  getGamesByProvider = async (
    providerId,
    countryPreview,
    offSet,
    more,
    isWhiteListed
  ) => {
    const initialUrl = `games/?provider=${providerId}${
      isWhiteListed ? '' : '&status__in=enabled,maintenance'
    }&limit=24&supports_${isMobile ? 'mobile' : 'desktop'}=true${
      countryPreview ? `&country_preview=${countryPreview}` : ''
    }${isWhiteListed ? '&skip_disabled_providers=false' : ''}`;

    const nextUrl = `games/?limit=24&offset=${offSet}&provider=${providerId}${
      isWhiteListed ? '' : '&status__in=enabled,maintenance'
    }&supports_${isMobile ? 'mobile' : 'desktop'}=true${
      countryPreview ? `country_preview=${countryPreview}` : ''
    }${isWhiteListed ? '&skip_disabled_providers=false' : ''}`;

    const gameUrl = more ? nextUrl : initialUrl;

    try {
      const gamesByProvider = await GamesApi.client.get(gameUrl);
      return gamesByProvider;
    } catch (error) {
      return { error: error.message, status: error.request.status };
    }
  };

  getFavoriteGamesByReference = async (reference) => {
    try {
      const favoriteGames = await GamesApi.client.get(
        `games/?reference__in=${reference}`
      );
      return favoriteGames;
    } catch (error) {
      return { error: error.message, status: error.request.status };
    }
  };

  search = async (
    searchText,
    categoryName,
    providerId,
    offSet,
    providerType
  ) => {
    let link;

    const isStudioSearch = providerType === 'studio';
    const searchFor = isStudioSearch ? 'studio' : 'provider';

    if (!categoryName && !providerId) {
      link = `games/?&status__in=enabled,maintenance&search=${searchText}&limit=24${
        offSet ? `&offset=${offSet}` : ''
      }&supports_${isMobile ? 'mobile' : 'desktop'}=true`;
    } else if (categoryName && !providerId) {
      link = `games/${categoryName}/?&status__in=enabled,maintenance&search=${searchText}&limit=24${
        offSet ? `&offset=${offSet}` : ''
      }&supports_${isMobile ? 'mobile' : 'desktop'}=true`;
    } else if (!categoryName && providerId) {
      link = `games/?${searchFor}=${providerId}&status__in=enabled,maintenance&search=${searchText}&limit=24${
        offSet ? `&offset=${offSet}` : ''
      }&supports_${isMobile ? 'mobile' : 'desktop'}=true`;
    } else {
      link = `games/${categoryName}/?${searchFor}=${providerId}&status__in=enabled,maintenance&search=${searchText}&limit=24${
        offSet ? `&offset=${offSet}` : ''
      }&supports_${isMobile ? 'mobile' : 'desktop'}=true`;
    }

    try {
      const searchResult = await GamesApi.client.get(link);
      return searchResult;
    } catch (error) {
      return { error: error.message, status: error.request.status };
    }
  };

  getPersonalizedSite = async ({
    playerID,
  }) => {
    const response = await GamesApi.client.get('game-recommendation-new/personalise-site/', {
      params: {
        playerID,
        recommendationType: 'personalise_site',
      },
    });

    return response;
  };

  getNewGames = async () => {
    const response = await GamesApi.client.get('games/', {
      params: {
        new_games_only: true,
        skip_disabled_providers: false,
      },
    });

    return response;
  };

  getRecommendedGames = async ({
    recommendationType,
    playerID,
    jurisdiction,
  }) => {
    const recommendedGames = await GamesApi.client.get('game-recommendation-new/', {
      params: {
        playerID,
        recommendationType,
        jurisdiction,
      },
    });

    return recommendedGames;
  };

  addGameLaunchCount = async (id) => {
    try {
      const res = await GamesApi.client.patch(`gamesite/${id}/`, { game_launched: true });
      return res;
    } catch (error) {
      console.error(error);
      return { error: error.message, status: error.request.status };
    }
  };
}

export const gamesApi = new Games();
