import React from 'react';
import { useLocation } from 'react-router-dom';
import GamesByCategories from './GamesByCategories';
import GamesByCategory from './GamesByCategory';
import useGames from '../../../hooks/games/useGames';
import FilteredGames from './FilteredGames';
import useActiveGames from '../../../hooks/games/useActiveGames';
import { FAVORITE_GAMES_CATEGORY_ID } from '../../../redux/actions/gamesActions/gamesAction';
import FavoriteGamesList from './FavoriteGamesList';
import FilteredGamesFromCms from './FilteredGamesFromCms';
import useQuery from '../../../hooks/useQuery';
import { TopGames } from './TopGames';
import { RecommendedGames } from './RecommendedGames';
import { PeopleArePlayingGames } from './PeopleArePlayingGames';
import { NewGames } from './NewGames';
import { AllGames } from './AllGames';
import CategoryWinnersList from '../CategoryWinnersLists';
import { isHomePage } from '../../Helpers';

function Games() {
  const { gamesByCategories, gamesByProviders } = useGames();
  const { query } = useQuery();
  const searchText = query.get('search');
  const { activeCategory, activeProvider, activeStudio } = useActiveGames();
  const location = useLocation();

  const activeTopGames = query?.get('category') === 'top-games';
  const activeAllGames = query?.get('category') === 'all-games';

  const activeRecommendedGames = query?.get('category') === 'recommended';

  const activePeopleArePlaying = query?.get('category') === 'people-are-playing';

  const activeNewGames = query?.get('category') === 'new-games';

  if (activeCategory?.id === FAVORITE_GAMES_CATEGORY_ID) {
    return <FavoriteGamesList />;
  }

  if (activeAllGames) {
    return <AllGames />;
  }
  if (activeTopGames) {
    return <TopGames />;
  }
  if (activeRecommendedGames) {
    return <RecommendedGames />;
  }
  if (activePeopleArePlaying) {
    return <PeopleArePlayingGames />;
  }
  if (activeNewGames) {
    return <NewGames />;
  }

  if ((searchText?.length ?? 0) >= 3) {
    return <FilteredGamesFromCms />;
  }

  if (
    (activeCategory?.id || activeTopGames) &&
    (activeProvider?.id || activeStudio?.id)
  ) {
    return <FilteredGames />;
  }

  if (activeCategory && !activeProvider && !activeStudio) {
    return <GamesByCategory category={gamesByCategories[activeCategory?.id]} />;
  }

  if (!activeCategory && (activeProvider || activeStudio)) {
    const currentProvider = Object.values(gamesByProviders)?.find(
      (provider) => {
        if (provider?.type === 'studio') {
          return provider?.slug === activeStudio?.slug;
        }
        return provider?.slug === activeProvider?.slug;
      }
    );
    return <GamesByCategory category={currentProvider} />;
  }

  return (
    <>
      <TopGames />
      <RecommendedGames />
      <NewGames />
      {isHomePage(location.pathname)
              && process.env.REACT_APP_SHOW_TOURNAMENTS_CARDS === 'true' && (
                <CategoryWinnersList />
      )}
      <PeopleArePlayingGames />
      <GamesByCategories categories={gamesByCategories} />
    </>
  );
}

export default Games;
